import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { getBacket } from "../../../redux/action-creaters/cart";
import styles from "./index.module.scss";
import Button from "/components/common/button";
import { showPopup } from "../../../redux/action-creaters/popup";
import { popupTypes } from "../../../redux/reducers/popupReducer";
import BasketItems from "/components/common/basketItems";
import { declOfNum } from "../../../utils";

export default function Cart() {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBacket());
  }, []);

  const { basket } = useSelector((state) => state.cart);

  const sum = useMemo(() => {
    return basket?.PRICE;
  }, [basket]);

  return (
    <div className={styles.Basket}>
      <h2>Товар добавлен в корзину</h2>
      <BasketItems />
      <div className={styles.Total}>
        <div className={styles.TotalItem}>
          <div>
            В корзине {declOfNum(basket?.QTY, ["товар", "товара", "товаров"])}
          </div>
          <span>на сумму {sum} руб</span>
        </div>
        <div className={styles.TotalButtons}>
          <Button
            onClick={() => {
              dispatch(showPopup(popupTypes.none));
            }}
            type={"outline"}
            label={"Продолжить покупки"}
          />
          <Button
            onClick={() => {
              router.push("/personal/cart/");
            }}
            label={"Оформить заказ"}
          />
        </div>
      </div>
    </div>
  );
}
