import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import { Api } from "/api";

export default function Manager({ productId }) {
  const [managerComment, setManagerComment] = useState("");
  const [managerData, setManagerData] = useState("");
  const [managerDataNew, setManagerDataNew] = useState("");

  const { user } = useSelector((state) => state.user);
  const router = useRouter();
  useEffect(() => {
    if (
      true ||
      user.IS_MANAGER ||
      user.IS_ADMIN ||
      Boolean(router.query.demo)
    ) {
      const id = productId;
      const getComments = async () => {
        const comment = await Api.catalog.getManagerComments([id]);
        setManagerComment(comment[id]);
      };
      const getData = async () => {
        const managerData = await Api.catalog.getManagerData([id]);
        setManagerData(managerData[id]);
      };
      const getDataNew = async () => {
        const managerData = await Api.server.getManagerDelivery([id]);
        setManagerDataNew(managerData[id]);
      };

      getComments();
      getData();
      getDataNew();
    }
  }, [user, productId]);

  return (
    <>
      {Boolean(managerComment) && (
        <p style={{ color: "red" }}>
          {managerComment}
          <br />
        </p>
      )}
      {Boolean(managerDataNew) && (
        <div>
          Новый блок:
          <div
            className={styles.ManagerData}
            dangerouslySetInnerHTML={{ __html: managerDataNew }}
          />
        </div>
      )}
      {Boolean(managerData) && (
        <div>
          Старый блок:
          <div
            className={styles.ManagerData}
            dangerouslySetInnerHTML={{ __html: managerData }}
          />
        </div>
      )}
    </>
  );
}
