import clsx from "clsx";
import styles from "./index.module.scss";

export default function ProductPageWrapper({
  head,
  left,
  bottom,
  children,
  customClass = "",
}) {
  return (
    <article
      itemScope
      itemType="http://schema.org/Product"
      className={clsx(customClass, styles.Product)}
    >
      <div className="container">
        {head}
        <div className={styles.Body}>
          <div className={styles.Left}>{left}</div>
          <div className={styles.Right}>{children}</div>
        </div>
      </div>
      {bottom}
    </article>
  );
}
