import clsx from "clsx";
import styles from "./index.module.scss";
import Icon from "../../../common/icon";

export default function Sert({ sert, isBaltex = false, isLanding = false }) {
  return sert?.length > 0 ? (
    <div className={clsx(isLanding && styles.SertLanding)}>
      {sert.map((item) => (
        <div className={styles.Files} key={item.NAME}>
          <a
            href={item.URL}
            className={styles.File}
            rel="noreferrer"
            target="_blank"
          >
            {isLanding && <Icon id="download" width={18} height={20} />}
            {isBaltex && <Icon id="baltexpdf" width={18} height={20} />}
            {!isLanding && !isBaltex && (
              <Icon fill="#1247a5" id={"pdf"} width={18} height={20} />
            )}
            <div className={styles.Name}>
              <div>{item.NAME}</div>
            </div>
          </a>
        </div>
      ))}
    </div>
  ) : null;
}
