import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useRouter } from "next/router";
import Button from "../../common/button";
import styles from "./index.module.scss";

export default function ProductVideos() {
  const { data } = useSelector((state) => state.popup);
  const router = useRouter();

  const video = useMemo(() => {
    if (data.video.length > 0) {
      const videoLink = Array.isArray(data.video) ? data.video[0] : data.video;

      if (videoLink.includes("vk.com")) {
        return videoLink;
      }
      if (videoLink.includes("rutube.ru")) {
        let fixedLink = videoLink;
        if (fixedLink.includes("/video/")) {
          const fullItems = fixedLink.split("/").filter((item) => item);
          fixedLink = `https://rutube.ru/play/embed/${fullItems[fullItems.length - 1]}/`;
        }
        return fixedLink;
      }

      let videoCode = videoLink.match(/[?&]v=([^&]*)/);
      if (!videoCode || !videoCode[1]) {
        videoCode = videoLink.match(/be[/]*(.+)/);
      }
      if (videoCode && videoCode[1]) {
        return `https://www.youtube.com/embed/${videoCode[1]}?rel=0&amp;autoplay=1`;
      }
    }
    return false;
  }, [data]);

  return (
    <div className={styles.Content}>
      <h1>{data.name}</h1>
      <iframe src={video}></iframe>
      <Button
        label={"Перейти в карточку товара"}
        type="outline"
        onClick={() => {
          router.push(data.link);
        }}
      />
    </div>
  );
}
