import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import { fetchReviews } from "../../../../../redux/action-creaters/product";
import Comment from "../comment";
import Loader from "/components/common/loader";

export default function Reviews({ productId }) {
  const dispatch = useDispatch();
  const { reviews } = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  useEffect(async () => {
    setLoading(true);
    dispatch(
      fetchReviews(
        productId,
        window.location.host.includes("localhost")
          ? "msk"
          : window.location.host.split(".")[0],
        Boolean(router.query.reset),
      ),
    );
    setLoading(false);
  }, []);

  return (
    <div>
      {loading && (
        <div className={styles.Loader}>
          <Loader />
        </div>
      )}
      {!loading &&
        reviews &&
        reviews?.map((review) => (
          <div key={review.ID} className={styles.Item}>
            <div className={styles.Author}>
              <div className={styles.Ava}>{review.NAME[0]}</div>
              <div className={styles.Name}>{review.NAME}</div>
              <div className={styles.Date}>{review.DATE}</div>
              <div className={styles.Rating}></div>
            </div>
            <div className={styles.Block}>
              {review.POSITIVE && (
                <div className={styles.Text}>
                  <span>Достоинства</span>
                  {review.POSITIVE}
                </div>
              )}
              {review.NEGATIVE && (
                <div className={styles.Text}>
                  <span>Недостатки</span>
                  {review.NEGATIVE}
                </div>
              )}
              {review.COMMENT && (
                <div className={styles.Text}>
                  <span>Отзыв</span>
                  {review.COMMENT}
                </div>
              )}

              {review.ANSWER && (
                <div className={styles.Comments}>
                  <Comment comment={review.ANSWER} />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
