import { useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";
import Icon from "../../icon";

export default function Props({
  props,
  filterUrl,
  isBaltex = false,
  isGray = false,
  isLanding = false,
}) {
  const { props: allProps } = useSelector((state) => state.props);
  return (
    <>
      {props?.map((prop) => (
        <div
          key={prop.ID}
          className={clsx(
            isGray && styles.PropBaltex,
            isLanding && styles.PropLanding,
            styles.Prop,
          )}
        >
          <span
            className={clsx(
              isLanding && styles.PropNameLanding,
              styles.PropName,
            )}
          >
            {allProps[prop.CODE]?.NAME || prop.NAME}
            {allProps[prop.CODE]?.HINT && (
              <span
                className={clsx(isLanding && styles.HintLanding, styles.Hint)}
              >
                <Icon
                  id={isLanding ? "coolicon" : "i"}
                  fill
                  width={20}
                  height={20}
                />
                <span className={styles.HintText}>
                  {allProps[prop.CODE]?.HINT}
                </span>
              </span>
            )}
          </span>

          <span
            className={clsx(
              isLanding && styles.PropValueLanding,
              styles.PropValue,
            )}
          >
            {prop.VALUE}
            {!isBaltex && !isLanding && (
              <>
                {(prop.NAME === "Электрика в комплекте" ||
                  prop.CODE === "ELEKTRIKA_V_KOMPLEKTE") &&
                  prop.VALUE === "нет" && (
                    <a
                      href={`/catalog/elektrika_farkopov/filter/${filterUrl}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      подобрать
                    </a>
                  )}
              </>
            )}
          </span>
        </div>
      ))}
    </>
  );
}
