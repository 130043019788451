import styles from "./index.module.scss";

export default function Comment({ comment }) {
  return (
    <div className={styles.Item}>
      <div className={styles.Ava}>A</div>
      <div className={styles.Name}>Администратор</div>
      <div className={styles.Date}></div>
      <div className={styles.Text}>
        <div dangerouslySetInnerHTML={{ __html: comment }} />
      </div>
    </div>
  );
}
