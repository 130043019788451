import { useSelector } from "react-redux";
import { useMemo } from "react";
import styles from "./index.module.scss";

export default function Team({ shopId, title = "Команда" }) {
  const { team } = useSelector((state) => state.content);

  const filteredTeam = useMemo(() => {
    if (shopId) {
      return team.filter((item) => item.PROPERTY_SHOP_ID_VALUE === shopId);
    }
    return team;
  }, [shopId]);

  return (
    filteredTeam.length > 0 && (
      <div>
        {title && <h2>{title}</h2>}
        <div className={styles.Jobs}>
          {filteredTeam.map((item) => (
            <div key={item.ID}>
              <img src={item.PICTURE_SRC} />
              <div>{item.NAME}</div>
              <span>{item.PROPERTY_WORK_VALUE}</span>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
