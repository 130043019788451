import Cookies from "js-cookie";
import { Api } from "../../api";
import { cacheFunctionData } from "/utils";

export const getUser = (reset = false) => {
  return async (dispatch) => {
    const user = await cacheFunctionData(Api.user.getUser, "user", reset);
    if (!user && reset) {
      const autoAuthId = Cookies.get("autoAuthId");

      if (autoAuthId) {
        const res = await Api.auth.authByCryptoId(autoAuthId);
        if (res.success) {
          window.location.reload();
        }
      }
    }
    dispatch({ type: "SET_USER", payload: user });
  };
};

export const logOut = () => {
  return async () => {
    await Api.user.logOut();
  };
};
export const getPersonalAutos = (reset = false) => {
  return async (dispatch) => {
    const autos = await cacheFunctionData(
      Api.user.getPersonalAutos,
      "personalAutos",
      reset,
    );
    dispatch({ type: "SET_AUTOS", payload: autos });
  };
};
