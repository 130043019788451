import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import Button from "@/components/common/button";
import { DeliveryTypes } from "~/types/cart";

import styles from "../index.module.scss";

export default function Shops({ currentType, onBuy, canBuy, isShow }: any) {
  const { shops } = useSelector((state: any) => state.location);
  const { delivery } = useSelector((state: any) => state.product);
  const { contactsData } = useSelector((state: any) => state.contacts);

  const shopsFiltered = useMemo(() => {
    if (contactsData?.SHOPS?.length > 0) {
      const filteredShops = Object.values(shops).filter((shop: any) => {
        // @ts-ignore
        if (currentType === DeliveryTypes.pickup) {
          return (
            Array.isArray(contactsData.SHOPS) &&
            contactsData.SHOPS.includes(shop.ID)
          );
        }
        return (
          Array.isArray(contactsData.INSTALL) &&
          contactsData.INSTALL.includes(shop.ID)
        );
      });
      return filteredShops.map((shop: any) => {
        return {
          ...shop,
          AVAL_DAYS_TEXT: delivery?.[shop.PROP_ID]?.AVAL_DAYS_TEXT,
          QTY_TEXT: delivery?.[shop.PROP_ID]?.QTY_TEXT,
        };
      });
    }
    return [];
  }, [shops, contactsData, delivery, currentType]);

  return (
    isShow && (
      <div className={styles.Table}>
        <span className={clsx(styles.TableFirst, styles.Head)}>
          Адреса магазинов
        </span>
        <span className={styles.Head}>Режим работы</span>
        <span className={styles.Head}>Наличие</span>
        <span className={styles.Head}>
          {currentType === DeliveryTypes.pickup && "Время самовывоза"}
        </span>
        <span className={styles.HeadLast} />

        {shopsFiltered.map((shop: any) => (
          <>
            <span className={styles.TableFirst}>{shop.NAME}</span>
            <span>
              <div className="muted">{shop.WORK_TIME}</div>
            </span>
            <span dangerouslySetInnerHTML={{ __html: shop.QTY_TEXT }} />
            <span>{shop.AVAL_DAYS_TEXT}</span>
            <span className={styles.TableButton}>
              {canBuy && (
                <Button
                  label="Оформить покупку"
                  type="outline"
                  onClick={onBuy}
                />
              )}
            </span>
          </>
        ))}
      </div>
    )
  );
}
