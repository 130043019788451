import { Api } from "@/api";
import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";

export default function BuyWithThis({ ids, isBaltex }) {
  const [buyWithThisProduct, setBuyWithThisProduct] = useState([]);

  const getProducts = async (ids) => {
    const products = await Api.product.getBuyWithThis(ids);
    setBuyWithThisProduct(products);
  };

  const products = useMemo(() => {
    return buyWithThisProduct ?? [];
  }, [buyWithThisProduct]);

  useEffect(() => {
    if (ids) {
      getProducts(ids);
    }
  }, [ids]);

  return (
    buyWithThisProduct?.length > 0 && (
      <div>
        <div className={styles.Title}>С этим товаром покупают</div>
        <div
          className={clsx(isBaltex && styles.ServicesBaltex, styles.Services)}
        >
          {products.map((item) => (
            <a
              key={item.ID}
              target={"_blank"}
              rel="noreferrer"
              href={item.DETAIL_PAGE_URL}
            >
              <span>
                <img src={item.PHOTO} />
              </span>
              <div>
                <span className={styles.Name}>{item.NAME}</span>
                <span className={styles.Text}>{item.PRICE} руб</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    )
  );
}
