import { useEffect } from "react";

export default function Split({ price = 0 }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pay.yandex.ru/sdk/v1/pay.js";
    script.async = true;
    script.onload = function () {
      if (document.querySelector("#yandexSplit")) {
        const paymentData = {
          // eslint-disable-next-line no-undef
          env: YaPay.PaymentEnv.Sandbox,
          version: 4,
          // eslint-disable-next-line no-undef
          currencyCode: YaPay.CurrencyCode.Rub,
          merchantId: "b880b38d-a12b-40bf-8bf3-5205af36dc70",
          totalAmount: price,
          availablePaymentMethods: ["CARD", "SPLIT"],
        };
        // eslint-disable-next-line no-undef
        YaPay.createSession(paymentData, {
          onPayButtonClick: () => {},
          onFormOpenError: () => {},
        }).then(function (paymentSession) {
          paymentSession.mountWidget(document.querySelector("#yandexSplit"), {
            // eslint-disable-next-line no-undef
            widgetType: YaPay.WidgetType.BnplPreview,
            // eslint-disable-next-line no-undef
            padding: YaPay.WidgetPaddingType.Default,
          });
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return <div id="yandexSplit"></div>;
}
