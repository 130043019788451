import { useEffect, useState } from "react";
import { Api } from "/api";
import { useSelector } from "react-redux";
import Input from "../../common/input";
import PopupFormWrapper from "../popupFormWrapper";

export default function ServiceOrder() {
  const [fields, setFields] = useState({});
  const [result, setResult] = useState({});
  const [success, setSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const masks = {
    Телефон: "+9 (999) 999 99-99",
  };

  const submit = async () => {
    setIsProcessing(true);
    const data = await Api.forms.serviceOrder(result);
    if (data.success) {
      setSuccess("Заявка отправлена.");
      if (
        window.location.href.includes(
          "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve",
        )
      ) {
        if (typeof ym !== "undefined") {
          // eslint-disable-next-line no-undef
          ym(20518312, "reachGoal", "mskRegFormSent");
        }
      } else if (
        window.location.href.includes(
          "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge",
        )
      ) {
        if (typeof ym !== "undefined") {
          // eslint-disable-next-line no-undef
          ym(20518312, "reachGoal", "spbRegFormSent");
        }
      }
    } else if (data.message) {
      setError(data.message);
      setFields(data.fields);
    }
    setIsProcessing(false);
  };

  const { data } = useSelector((state) => state.popup);

  useEffect(async () => {
    setLoading(true);
    const fields = await Api.forms.serviceOrder({ getFields: true });
    setFields(fields);
    // eslint-disable-next-line array-callback-return
    fields.map((field) => {
      if (field.NAME.includes("roistat")) {
        setResult((fields) => ({
          ...fields,
          [field.CODE]: window.roistat?.visit,
        }));
      }
    });
    setResult((fields) => ({ ...fields, form_text_7: data }));
    setLoading(false);
  }, []);

  return (
    <PopupFormWrapper
      title="Заказать услугу"
      isLoading={isLoading}
      isProcessing={isProcessing}
      onSubmit={submit}
      errorText={error}
      successText={success}
    >
      {Object.values(fields).map(({ NAME, CODE, REQUIRED, ERROR, TYPE }) => (
        <Input
          key={CODE}
          label={NAME}
          required={REQUIRED}
          mask={masks[NAME]}
          onChange={(value) => setResult({ ...result, [CODE]: value })}
          isError={ERROR}
          isMultiple={TYPE === "textarea"}
          value={result[CODE]}
        />
      ))}
    </PopupFormWrapper>
  );
}
