import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../input";
import { Api } from "../../../api";
import Button from "/components/common/button";
import styles from "./index.module.scss";
import { getUser } from "../../../redux/action-creaters/user";
import { showPopup } from "../../../redux/action-creaters/popup";
import { popupTypes } from "../../../redux/reducers/popupReducer";

export default function Auth({ isCart = false, isErrorInput = false }) {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  const [phoneConfirmed, setPhoneConfirmed] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [timer, setTimer] = useState(0);
  const [error, setError] = useState("");

  const submit = async () => {
    setError("");
    if (phone.length !== 11) {
      setError("Введите корректный номер телефона");
      return;
    }

    if (code.length !== 6) {
      setError("Введите корректный код");
      return;
    }

    const data = await Api.auth.auth({ phone, code });
    // eslint-disable-next-line no-use-before-define
    if (!isError(data)) {
      dispatch(getUser(true));
      dispatch(showPopup(popupTypes.none));

      if (typeof ym !== "undefined") {
        // eslint-disable-next-line no-undef
        ym(20518312, "reachGoal", "authorized");
      }
    }
  };

  const inputPhone = async (phone) => {
    // eslint-disable-next-line no-param-reassign
    phone = phone.replace(/\D+/g, "");
    if (phone.length === 11) {
      const data = await Api.auth.checkUser(phone);

      if (data.PHONE.SUCCESS) {
        setPhoneConfirmed(true);
        if (data.RESULT) {
          /* empty */
        } else {
          await Api.auth.getCode(phone);
          setNewUser(true);
        }
      } else {
        setError("Некорректный телефон");
      }
    }
    setPhone(phone);
  };

  const inputCode = (code) => {
    setCode(code);
  };

  const changePhone = () => {
    setPhone("");
    setError("");
    setPhoneConfirmed(false);
    setNewUser(false);
  };

  let resetInterval = false;

  const initTimer = function (newTimer) {
    if (resetInterval) {
      clearInterval(resetInterval);
    }
    let timerCounter = newTimer;
    setTimer(timerCounter);
    resetInterval = setInterval(() => {
      // eslint-disable-next-line no-plusplus
      timerCounter--;
      if (timerCounter <= 0) {
        clearInterval(resetInterval);
      }
      setTimer(timerCounter);
    }, 1000);
  };

  const getCode = async () => {
    const data = await Api.auth.getCode(phone);
    // eslint-disable-next-line no-use-before-define
    if (!isError(data)) {
      if (data.TYPE === "SUCCESS") {
        initTimer(59);
      } else if (data.TYPE === "TIMER") {
        initTimer(data.VALUE);
      }
    }
  };

  const isError = (data) => {
    setError("");
    if (typeof data.TYPE !== "undefined" && data.TYPE === "ERROR") {
      setError(data.TEXT);
      return true;
    }
    return false;
  };

  const showResetButton = useMemo(() => {
    return !(timer > 0) && phoneConfirmed;
  }, [timer, phoneConfirmed]);

  useEffect(() => {}, [phoneConfirmed]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        submit();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const popupElement = document.querySelector(`#authForm`);
    if (popupElement) {
      const firstInput = popupElement.querySelector("input, textarea, select");
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, []);

  return (
    <div className={styles.Content} id="authForm">
      {error && <span className={styles.Error}>{error}</span>}
      <Input
        label="Телефон"
        mask="+9 (999) 999 99-99"
        required={true}
        onChange={inputPhone}
        disabled={phoneConfirmed}
        value={phone}
        isError={isErrorInput}
      />
      {phoneConfirmed && (
        <>
          <div>
            <span className={styles.Change} onClick={changePhone}>
              Изменить номер
            </span>
          </div>
          {isNewUser || !showResetButton ? (
            <span>
              Вам был отправлен код по смс. Введите код и нажмите Подтвердить
            </span>
          ) : (
            <span>
              Ранее Вы получали по смс код для авторизации.
              <br />
              Введите пожалуйста код и нажмите Подтвердить.
              <br />
            </span>
          )}
        </>
      )}
      {((isCart && phoneConfirmed) || !isCart) && (
        <>
          <Input
            label="Код"
            mask="999999"
            required={true}
            onChange={inputCode}
          />
          <div className={styles.Buttons}>
            <Button onClick={submit} label={"Войти"} />
            {showResetButton && (
              <Button
                type="outline"
                onClick={getCode}
                label={"Получить код по СМС"}
              />
            )}
          </div>
          {phoneConfirmed && (
            <div>
              {!showResetButton && (
                <div>Получить новый код можно через {timer} сек.</div>
              )}
              {!isNewUser && showResetButton && (
                <span>
                  Если Вы не помните код - нажмите кнопку «Получить код по СМС»
                </span>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
