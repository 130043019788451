import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Api } from "@/api";
import Button from "@/components/common/button";
import Loader from "@/components/common/loader";
import { setDeliveryListAdditional } from "@/redux/action-creaters/cart";
import { showPopup } from "@/redux/action-creaters/popup";
import { popupTypes } from "@/redux/reducers/popupReducer";
import { DeliveryTypes } from "~/types/cart";

import styles from "../index.module.scss";

export default function Edost({ onBuy, canBuy, product, isShow }: any) {
  const { cityName, deliveyCityData, deliveyCityDataLoaded } = useSelector(
    (state: any) => state.location,
  );
  const dispatch = useDispatch();
  const [edost, setPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { deliveryList } = useSelector((state: any) => state.cart);

  const getEdostPoints = async () => {
    setIsLoading(true);
    const points = await Api.server.postCalcEdost({
      city_name_new: cityName,
      kladr_id: deliveyCityData?.kladr_id,
      productId: product.ID,
    });
    let host = "";
    if (typeof window !== "undefined") {
      // eslint-disable-next-line prefer-destructuring
      host = window.location.host.split(".")[0];
    }
    const edostItem = deliveryList.find((item: any) => {
      return item.TYPE === DeliveryTypes.edost;
    });
    const edostDiscountPrice = edostItem.DISCOUNT?.[host] || 0;
    let minPriceVal = -1;
    const tarrifs = points.TARIFFS
      ? points.TARIFFS.map((item: any) => {
          let price = parseInt(item.price, 10);
          if (price < 0) {
            price = 0;
          }
          if (minPriceVal < 0 || price < minPriceVal) {
            minPriceVal = price;
          }
          return {
            ...item,
            price: parseInt(item.price, 10) - edostDiscountPrice,
            discount: edostDiscountPrice,
            oldPrice: parseInt(item.price, 10),
          };
        })
      : [];

    setPoints(tarrifs);
    dispatch(
      setDeliveryListAdditional(
        points.DELIVERY_LIST?.map((item: any) => {
          let { PRICE } = item;
          let { DESCRIPTION } = item;
          const ID = parseInt(item.ID, 10);
          let IS_PRICE_FROM = false;
          // eslint-disable-next-line eqeqeq
          if (ID == 4) {
            IS_PRICE_FROM = true;
            PRICE = minPriceVal;
            // DESCRIPTION = minPriceVal > 0 ? `от ${minPriceVal} руб` : 'бесплатно';
          }
          // eslint-disable-next-line eqeqeq
          if (ID == 39) {
            DESCRIPTION = `${item.PRICE} руб`;
          }
          return {
            ID,
            NAME: item.NAME,
            IS_PRICE_FROM,
            DESCRIPTION,
            PARENT_ID: "",
            PRICE,
            TYPE: item.TYPE,
            LOCATION: "",
            PRICE_RESTRICTIONS: null,
          };
        }),
      ),
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (cityName && deliveyCityDataLoaded) {
      getEdostPoints();
    }
  }, [cityName, deliveyCityDataLoaded]);

  const edostFormated = useMemo(() => {
    return edost ? edost.sort((a: any, b: any) => a.price - b.price) : [];
  }, [edost]);

  return (
    isShow && (
      <>
        {isLoading && (
          <div className={styles.Loader}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {edostFormated.length === 0 ? (
              <div>
                <p>
                  Не удалось рассчитать доставку в ваш город. Выберите другой
                  город или уточните у менеджера способы доставки:
                </p>
                <div>
                  <Button
                    customStyles={styles.FormButton}
                    onClick={() => dispatch(showPopup(popupTypes.backCall))}
                    label="Уточнить способы доставки"
                  />
                </div>
              </div>
            ) : (
              <div className={clsx(styles.TableMiddle, styles.Table)}>
                <span className={styles.Head}>Название компании</span>
                <span className={styles.Head}>Сроки доставки</span>
                <span className={styles.Head}>Стоимость</span>
                <span className={styles.HeadLast} />
                {edostFormated.map((item: any) => (
                  <>
                    <span>{item.company}</span>
                    <span>{item.day}</span>
                    <span>
                      {item.price > 0 ? (
                        <>
                          {item.discount > 0 && (
                            <span className={styles.OldPrice}>
                              {item.oldPrice} руб
                            </span>
                          )}
                          <div>{parseInt(item.price, 10)} руб</div>
                        </>
                      ) : (
                        <div>бесплатно</div>
                      )}
                    </span>
                    <span className={styles.TableButton}>
                      {canBuy && (
                        <Button
                          label="Оформить покупку"
                          type="outline"
                          onClick={onBuy}
                        />
                      )}
                    </span>
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </>
    )
  );
}
