import styles from "./index.module.scss";

export default function Brand({ data }) {
  return data?.name ? (
    <div className={styles.Brand}>
      <a href={data.detalPageUrl}>
        <img src={data.pictureSrc} />
      </a>
    </div>
  ) : null;
}
