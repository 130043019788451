import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";
import Icon from "../../../common/icon";
import { showPopup } from "../../../../redux/action-creaters/popup";
import { popupTypes } from "../../../../redux/reducers/popupReducer";
import { declOfNum } from "../../../../utils";

export default function Services({ isBaltex = false, productId }) {
  const dispatch = useDispatch();
  const { qestionsAndReviewsCount } = useSelector((state) => state.section);
  const questions = qestionsAndReviewsCount[productId]?.QUESTIONS || 0;
  const reviews = qestionsAndReviewsCount[productId]?.REVIEWS || 0;

  return (
    <div className={clsx(isBaltex && styles.TopBaltex, styles.Top)}>
      <div className={styles.Article}></div>
      <div className={styles.Communicate}>
        <div
          onClick={() =>
            dispatch(showPopup(popupTypes.addReview, { id: productId }))
          }
        >
          <Icon fill="#9294A9" id="comment" width={15} height={14} />
          {reviews > 0
            ? `${declOfNum(reviews, ["отзыв", "отзыва", "отзывов"])}`
            : "Оставить отзыв"}
        </div>
        <div
          onClick={() =>
            dispatch(showPopup(popupTypes.addQuestion, { id: productId }))
          }
        >
          <Icon fill="#9294A9" id="question" width={15} height={14} />
          {questions > 0
            ? `${declOfNum(questions, ["вопрос", "вопроса", "вопросов"])}`
            : "Задать вопрос"}
        </div>
      </div>
    </div>
  );
}
