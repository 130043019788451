import clsx from "clsx";
import InputMask from "react-input-mask";

import styles from "./index.module.scss";

export default function Input({
  label,
  value,
  mask,
  after,
  right,
  placeholder,
  required = false,
  isMultiple,
  onChange = () => {},
  onBlur = () => {},
  onClick,
  isError,
  error = "",
  disabled,
  classProp,
}: any) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={onClick}
      className={clsx(
        styles.Input,
        classProp,
        disabled && styles.Disabled,
        isError && styles.Error,
        label.includes("roistat") && styles.Roistat,
      )}
    >
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label>
          {label}
          {required && "*"}
        </label>
      )}
      <div className={styles.Content}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {disabled ? (
          <div>{value}</div>
        ) : isMultiple ? (
          <textarea
            // @ts-ignore
            onChange={(event) => onChange(event.target.value)}
            // @ts-ignore
            placeholder={placeholder}
          >
            {value}
          </textarea>
        ) : (
          // @ts-ignore
          <InputMask
            mask={mask}
            placeholder={placeholder}
            // @ts-ignore
            onChange={(event) => onChange(event.target.value)}
            // @ts-ignore
            onBlur={(event) => onBlur(event.target.value)}
            value={value}
          />
        )}
        {right && <span className={styles.Right}>{right}</span>}
      </div>
      {error && (
        <span className={styles.Error}>{error ?? "Заполните поле"}</span>
      )}
      {after && <span className={styles.After}>{after}</span>}
    </div>
  );
}
