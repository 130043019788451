export enum DeliveryTypes {
  pickup = "pickup",
  install = "install",
  courier = "courier",
  sdek = "sdek",
  edost = "edost",
  edostCourier = "edostCourier",
}

export enum DeliveryId {
  edost = 4,
  edostOld = 10,

  pickupSpb = 2,
  installSpb = 3,
  courierSpb = 5,
  courierSpbKad = 6,

  courierMsk = 8,
  installMsk = 9,
  pickupMsk = 18,
  sdekMsk = 26,

  installNN = 28,
  pickupNN = 35,
}

/*
export const DeliveryIdTypes = {
    [DeliveryTypes.pickup]: [
        DeliveryId.pickupMsk,
        DeliveryId.pickupSpb,
        DeliveryId.pickupNN,
    ],
    [DeliveryTypes.install]: [
        DeliveryId.installMsk,
        DeliveryId.installSpb,
        DeliveryId.installNN
    ],
    [DeliveryTypes.courier]: [
        DeliveryId.courierMsk,
        DeliveryId.courierSpb,
        DeliveryId.courierSpbKad
    ],
    [DeliveryTypes.sdek]: [
        DeliveryId.sdekMsk
    ],
    [DeliveryTypes.edost]: [
        DeliveryId.edost
    ],
} */

export enum UserFields {
  name = "NAME",
  address = "ADR",
  phone = "PHONE",
  email = "EMAIL",
  location = "LOC",
  passport = "PASPORT",
  pointId = "POINT_ID",
  pointInfo = "POINT_INFO",
  installTime = "INSTALL_TIME",
  deliveryTime = "DELIVERY_TIME",
  deliveryPrice = "TK_PRICE_DELIVERY",
  deliveryOldPrice = "TK_PRICE_OLD_DELIVERY",
  deliveryDays = "DELIVERY_DAYS",
  payType = "PAY_TYPE",
  deliveryId = "DELIVERY_ID",
  paySystemId = "PAY_SYSTEM_ID",
  total = "TOTAL",
  city = "CITY",
}
