import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import SliderComponent from "/components/common/slider";

export default function Slider() {
  const { data } = useSelector((state) => state.popup);

  return (
    <div className={styles.Content}>
      <SliderComponent images={data.images} />
    </div>
  );
}
