import { Api } from "../../api";
import { cacheFunctionData } from "/utils";

export const getDeliveryList = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const response = await Api.cart.getDeliveryList(Redis, reset);
    dispatch({ type: "SET_DELIVERY_LIST", payload: response });
  };
};

export const setDeliveryListAdditional = (list) => {
  return async (dispatch) => {
    dispatch({ type: "SET_DELIVERY_LIST_ADDITIONAL", payload: list });
  };
};

export const getPaymentList = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const response = await Api.cart.getPaymentList(Redis, reset);
    dispatch({ type: "SET_PAYMENT_LIST", payload: response });
  };
};

export const getBacket = (reset = false) => {
  return async (dispatch) => {
    dispatch({ type: "SET_BASKET_LOADING", payload: true });
    const data = await cacheFunctionData(Api.basket.getBasket, "basket", reset);
    dispatch({ type: "SET_BASKET", payload: data });
    dispatch({ type: "SET_BASKET_LOADING", payload: false });
  };
};

export const setParam = (param) => {
  return async (dispatch) => {
    dispatch({ type: "SET_CART_PARAM", payload: param });
  };
};
