import { useEffect, useState } from "react";
import Input from "../../common/input";
import { Api } from "/api";
import PopupFormWrapper from "../popupFormWrapper";

export default function BackCall() {
  const [fields, setFields] = useState({});
  const [result, setResult] = useState({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const masks = {
    Телефон: "+9 (999) 999 99-99",
  };

  const submit = async () => {
    setIsProcessing(true);
    const data = await Api.forms.backCall(result);
    if (data.success) {
      setSuccess(`Заявка отправлена. Номер вашей заявки #${data.success}`);

      if (
        window.location.href.includes(
          "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve",
        )
      ) {
        if (typeof ym !== "undefined") {
          // eslint-disable-next-line no-undef
          ym(20518312, "reachGoal", "mskRegFormSent");
        }
      } else if (
        window.location.href.includes(
          "vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge",
        )
      ) {
        if (typeof ym !== "undefined") {
          // eslint-disable-next-line no-undef
          ym(20518312, "reachGoal", "spbRegFormSent");
        }
      }
    } else if (data.message) {
      setError(data.message);
      setFields(data.fields);
    }
    setIsProcessing(false);
  };

  useEffect(async () => {
    setLoading(true);
    const fields = await Api.forms.backCall({ getFields: true });
    setFields(fields);
    fields.forEach((field) => {
      if (field.NAME.includes("roistat")) {
        setResult({ [field.CODE]: window.roistat?.visit });
      }
    });
    setLoading(false);
  }, []);

  return (
    <PopupFormWrapper
      title="Заказать звонок"
      isLoading={isLoading}
      isProcessing={isProcessing}
      onSubmit={submit}
      errorText={error}
      successText={success}
      topText={"Мы свяжемся с вами в ближайшее время"}
    >
      {Object.values(fields).map(({ NAME, CODE, REQUIRED, ERROR }) => (
        <Input
          key={CODE}
          label={NAME}
          required={REQUIRED}
          mask={masks[NAME]}
          onChange={(value) => setResult({ ...result, [CODE]: value })}
          isError={ERROR}
        />
      ))}
    </PopupFormWrapper>
  );
}
