import { useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";

export default function Adv({ rows = 3 }) {
  const { mainAdv } = useSelector((state) => state.content);

  return (
    <div className={styles.Adv}>
      <div className={clsx(styles.AdvList, styles[`AdvListRow_${rows}`])}>
        {mainAdv.map((adv) => (
          <div key={adv.ID}>
            <div
              dangerouslySetInnerHTML={{
                __html: adv["~PROPERTY_SVG_ICON_VALUE"]?.TEXT,
              }}
            ></div>
            <span>{adv.NAME}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
