import { useSelector } from "react-redux";
import styles from "./index.module.scss";

export default function Video() {
  const { data } = useSelector((state) => state.popup);

  return (
    <div className={styles.Content}>
      <iframe
        src={`https://www.youtube.com/embed/${data}?rel=0&autoplay=1`}
      ></iframe>
    </div>
  );
}
