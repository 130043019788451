import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import { fetctQuestions } from "../../../../../redux/action-creaters/product";
import Comment from "../comment";
import Loader from "/components/common/loader";

export default function Questions({ productId }) {
  const dispatch = useDispatch();
  const { questions } = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  useEffect(async () => {
    setLoading(true);
    await dispatch(
      await fetctQuestions(
        productId,
        window.location.host.includes("localhost")
          ? "msk"
          : window.location.host.split(".")[0],
        Boolean(router.query.reset),
      ),
    );
    setLoading(false);
  }, []);

  return (
    <div>
      {loading && (
        <div className={styles.Loader}>
          <Loader />
        </div>
      )}
      {!loading &&
        questions &&
        questions?.map((question) => (
          <div key={question.ID} className={styles.Item}>
            <div className={styles.Author}>
              <div className={styles.Ava}>{question.NAME[0]}</div>
              <div className={styles.Name}>{question.NAME}</div>
              <div className={styles.Date}>{question.DATE}</div>
              <div className={styles.Rating}></div>
            </div>
            <div className={styles.Block}>
              {question.QUESTION && (
                <div className={styles.Text}>
                  <span>Вопрос</span>
                  {question.QUESTION}
                </div>
              )}

              {question.ANSWER && (
                <div className={styles.Comments}>
                  <Comment comment={question.ANSWER} />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
