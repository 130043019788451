import clsx from "clsx";

import Button from "@/components/common/button";

import styles from "../index.module.scss";

export default function EdostCourier({ onBuy, canBuy, price, isShow }: any) {
  return (
    isShow && (
      <div className={clsx(styles.TableSmall, styles.Table)}>
        <span className={styles.Head}>Условия доставки</span>
        <span className={styles.Head}>Стоимость</span>
        <span className={styles.HeadLast} />
        <span className={styles.Adress}>Доставка курьером до двери</span>
        <span>{price}</span>
        <span className={styles.TableButton}>
          {canBuy && (
            <Button label="Оформить покупку" type="outline" onClick={onBuy} />
          )}
        </span>
      </div>
    )
  );
}
