import clsx from "clsx";
import styles from "./index.module.scss";

export default function Stick({ stick }) {
  return stick?.NAME ? (
    <p className={clsx(styles.Stick, styles[`Stick--${stick?.CODE}`])}>
      {stick?.NAME}
    </p>
  ) : null;
}
