import Input from "../../common/input";
import { useEffect, useMemo, useState } from "react";
import { Api } from "/api";
import { useSelector } from "react-redux";
import PopupFormWrapper from "../popupFormWrapper";
import Mini from "/components/common/product/mini";

export default function AddComment() {
  const [fields, setFields] = useState([]);
  const [result, setResult] = useState({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const submit = async () => {
    setIsProcessing(true);
    const data = await Api.forms.subscribeProduct(result);
    if (data.success) {
      setSuccess(`При поступлении товара вам поступит уведомление на почту`);
    } else if (data.message) {
      setError(data.message);
      setFields(data.fields);
    }
    setIsProcessing(false);
  };

  const masks = {
    Телефон: "+9 (999) 999 99-99",
  };

  const { data } = useSelector((state) => state.popup);

  useEffect(async () => {
    setLoading(true);
    const fields = await Api.forms.subscribeProduct({ getFields: true });
    setFields(fields);
    fields.forEach((field) => {
      if (field.NAME.includes("roistat")) {
        setResult((fields) => ({
          ...fields,
          [field.CODE]: window.roistat?.visit,
        }));
      }
    });
    setResult((fields) => ({ ...fields, form_text_25: data.name }));
    setLoading(false);
  }, []);

  const fieldsFiltered = useMemo(() => {
    return fields.filter(({ CODE }) => CODE !== "form_text_25");
  }, [fields]);

  return (
    <PopupFormWrapper
      title="Оформление предзаказа"
      isLoading={isLoading}
      isProcessing={isProcessing}
      onSubmit={submit}
      errorText={error}
      successText={success}
      topText={"При поступлении товара вам поступит уведомление на почту"}
      sentText="Оставить заявку"
    >
      <Mini productId={data.id} />
      {fieldsFiltered.map(({ NAME, CODE, REQUIRED, ERROR, TYPE }) => (
        <Input
          key={CODE}
          label={NAME}
          required={REQUIRED}
          onChange={(value) => setResult({ ...result, [CODE]: value })}
          isError={ERROR}
          mask={masks[NAME]}
          isMultiple={TYPE === "HTML"}
        />
      ))}
    </PopupFormWrapper>
  );
}
