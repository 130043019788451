import { useSelector } from "react-redux";
import { useMemo } from "react";
import Link from "next/link";
import styles from "./index.module.scss";
import { getProductPhotoById } from "../../../../utils";

export default function Props({ price, productId }) {
  const { products } = useSelector((state) => state.section);
  const { product } = useSelector((state) => state.product);

  // eslint-disable-next-line consistent-return
  const productData = useMemo(() => {
    if (product?.ID === productId) {
      return {
        name: product.NAME,
        url: product.DETAIL_PAGE_URL,
        price: product.CATALOG.MIN_PRICE,
        photo: getProductPhotoById(product.PHOTOS_NEW)?.[0]?.thumb,
      };
    }
    const catalogProduct = products.find(({ ID }) => ID === productId);
    if (catalogProduct) {
      return {
        name: catalogProduct.NAME,
        url: catalogProduct.DETAIL_PAGE_URL,
        price: catalogProduct.CATALOG.MIN_PRICE,
        photo: getProductPhotoById(catalogProduct.PHOTOS_NEW)?.[0]?.thumb,
      };
    }
  }, [productId]);

  return (
    <Link href={productData.url}>
      <a className={styles.Product}>
        <div className={styles.ProductPhoto}>
          <img src={productData.photo} />
        </div>
        <div className={styles.ProductInfo}>
          <span>{productData.name}</span>
          <span>{price || productData.price} руб</span>
        </div>
      </a>
    </Link>
  );
}
