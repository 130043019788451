import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Api } from "../../../../api";
import styles from "./index.module.scss";
import Icon from "../../../common/icon";

export default function Services({ sectionId, isLanding }) {
  const { cityName } = useSelector((state) => state.location);
  const [services, setServices] = useState([]);
  const router = useRouter();

  const getService = async (cityName) => {
    if (!isLanding) {
      // eslint-disable-next-line eqeqeq
      if (sectionId != 635) {
        return;
      }
      if (cityName !== "Москва" && cityName !== "Санкт-Петербург") {
        return;
      }
    }
    const services = await Api.server.getServicesListCard(
      window.location.host,
      Boolean(router.query.reset),
    );
    setServices(services);
  };

  useEffect(() => {
    getService(cityName);
  }, [cityName]);

  return (
    services?.length > 0 && (
      <div className={isLanding ? styles.ServicesContainerLanding : ""}>
        <div className={styles.Title}>
          {isLanding ? "Услуги наших партнеров" : "Услуги"}
        </div>
        <div className={styles.Services}>
          {services.map((item) => (
            <>
              <a key={item.id} href={`/services/${item.code}/`}>
                {isLanding && (
                  <div className={styles.ArrowLanding}>
                    <Icon
                      id="chevron_right"
                      fill={"#FFFFFF"}
                      width={20}
                      height={20}
                    />
                  </div>
                )}
                <div>
                  <span className={styles.Name}>{item.name}</span>
                  <span
                    className={styles.Text}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></span>
                  {!isLanding && <span className={styles.Link}>Подробнее</span>}
                </div>
                <span className={styles.ImgLanding}>
                  <img src={item.image} />
                </span>
              </a>
            </>
          ))}
        </div>
      </div>
    )
  );
}
