import clsx from "clsx";
import styles from "./index.module.scss";

export default function Checkbox({ onChange, checked, label }) {
  return (
    <div className={styles.Checkbox} onClick={onChange}>
      <span className={clsx(styles.Box, checked && styles.BoxChecked)} />
      <span>{label}</span>
    </div>
  );
}
