import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import ProductDelivery from "./deliveryNew";
import Props from "../../../common/product/props";
import Button from "/components/common/button";
import { showPopup } from "../../../../redux/action-creaters/popup";
import { popupTypes } from "../../../../redux/reducers/popupReducer";
import Reviews from "./reviews";
import Questions from "./questions";
import Files from "../files";
import Sert from "../sert";

const tabs = {
  description: "description",
  delivery: "delivery",
  reviews: "reviews",
  questions: "questions",
};

export default function Bottom({ isLanding = false }) {
  const { product } = useSelector((state) => state.product);

  useEffect(() => {
    setTimeout(() => {
      if (typeof window === "undefined") {
        return;
      }
      Object.values(tabs).forEach((tab) => {
        if (window.location.hash === `#${tab}`) {
          const section = document.getElementById(tab);
          section.click();
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }, 0);
  }, []);

  const [currentTab, setTab] = useState(tabs.description);

  const dispatch = useDispatch();

  const tabsNames = {
    [tabs.description]: "Описание",
    [tabs.delivery]: "Наличие и доставка",
    [tabs.reviews]: "Отзывы",
    [tabs.questions]: "Вопросы и ответы",
  };

  useEffect(() => {
    if (currentTab === tabs.delivery) {
      /* empty */
    }
    if (currentTab === tabs.reviews) {
      if (typeof ym !== "undefined") {
        // eslint-disable-next-line no-undef
        ym(20518312, "reachGoal", "detailPageReviews");
      }
    }
    if (currentTab === tabs.questions) {
      if (typeof ym !== "undefined") {
        // eslint-disable-next-line no-undef
        ym(20518312, "reachGoal", "detailPageQuestions");
      }
    }
  }, [currentTab]);

  const router = useRouter();
  useEffect(() => {
    if (router.query.review) {
      dispatch(
        showPopup(popupTypes.addReview, {
          id: product.ID,
        }),
      );
    }
  }, []);

  const { qestionsAndReviewsCount } = useSelector((state) => state.section);
  const questions = qestionsAndReviewsCount[product.ID]?.QUESTIONS || 0;
  const reviews = qestionsAndReviewsCount[product.ID]?.REVIEWS || 0;

  return (
    <div className={clsx(isLanding && styles.InfoLanding, styles.Info)}>
      <div className="container">
        {!isLanding && (
          <div className={styles.Sections}>
            {Object.values(tabs).map((tab) => (
              <span
                onClick={() => {
                  setTab(tab);
                }}
                key={tab}
                id={tab}
                className={clsx(currentTab === tab && styles.Selected)}
              >
                {tabsNames[tab]}
                {tab === tabs.reviews && product.REVIEWS > 0
                  ? ` (${product.REVIEWS})`
                  : ""}
                {tab === tabs.questions && product.QUESTIONS > 0
                  ? ` (${product.QUESTIONS})`
                  : ""}
              </span>
            ))}
          </div>
        )}

        {currentTab === tabs.description && (
          <div>
            {product.DETAIL_TEXT ? (
              <>
                {isLanding && (
                  <div className={styles.TitleDetailText}>Описание</div>
                )}
                <div className={"text"}>
                  <div
                    itemProp="description"
                    dangerouslySetInnerHTML={{ __html: product.DETAIL_TEXT }}
                  ></div>
                </div>
              </>
            ) : (
              <div>
                <p>
                  Нет описания. Если у вас появились вопросы по товару -
                  закажите обратный звонок:
                </p>
                <div>
                  <Button
                    customStyles={styles.FormButton}
                    onClick={() => dispatch(showPopup(popupTypes.backCall))}
                    label={"Заказать звонок"}
                  />
                </div>
              </div>
            )}
            {(product.PROPERTIES.length > 0 || product.FILE) && !isLanding && (
              <div className={styles.Bottom}>
                {product.PROPERTIES.length > 0 && (
                  <div className={styles.Props}>
                    <div className={styles.SubTitle}>Характеристики</div>
                    <Props
                      props={product.PROPERTIES}
                      filterUrl={product.FILTER_URL}
                    />
                  </div>
                )}
                {product.FILE && (
                  <div className={styles.Docs}>
                    <div className={styles.SubTitle}>Комплектация</div>
                    <ul>
                      <li>
                        {product.NAME}
                        {product.ARTICLE?.VALUE &&
                          ` (арт: ${product.ARTICLE?.VALUE})`}
                        {product.BRAND?.name && `, ${product.BRAND?.name}`}
                      </li>
                      {product.FILE && (
                        <li>
                          <Files file={product.FILE} />
                        </li>
                      )}
                      {product.CERT.length > 0 && (
                        <li>
                          <Sert sert={product.CERT} />
                        </li>
                      )}
                      <li>Комплект метизов для установки</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {currentTab === tabs.delivery && <ProductDelivery product={product} />}

        {currentTab === tabs.reviews && (
          <div>
            {reviews > 0 ? (
              <Reviews productId={product.ID} />
            ) : (
              <p>Пока нет ни одного отзыва, будьте первым!</p>
            )}
            <Button
              customStyles={styles.FormButton}
              onClick={() =>
                dispatch(
                  showPopup(popupTypes.addReview, {
                    id: product.ID,
                  }),
                )
              }
              label={"Оставить отзыв о товаре"}
            />
          </div>
        )}

        {currentTab === tabs.questions && (
          <div>
            <div>
              {questions > 0 ? (
                <Questions productId={product.ID} />
              ) : (
                <p>Пока нет ни одного вопроса, будьте первым!</p>
              )}

              <Button
                customStyles={styles.FormButton}
                onClick={() =>
                  dispatch(
                    showPopup(popupTypes.addQuestion, {
                      id: product.ID,
                    }),
                  )
                }
                label={"Задать вопрос по товару"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
