import { Api } from "../../api";

export const fetchMainSlider = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainSlider(Redis, reset);
    dispatch({ type: "FETCH_MAIN_SLIDER", payload: ITEMS });
  };
};

export const fetchMainPopular = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainPopular(Redis, reset);
    dispatch({ type: "FETCH_MAIN_POPULAR", payload: ITEMS });
  };
};

export const fetchMainNumbers = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainNumbers(Redis, reset);
    dispatch({ type: "FETCH_MAIN_NUMBERS", payload: ITEMS });
  };
};

export const fetchMainNumbersBaltex = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.baltex.getMainNumbers(Redis, reset);
    dispatch({ type: "FETCH_MAIN_NUMBERS", payload: ITEMS });
  };
};

export const fetchMainAdv = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainAdv(Redis, reset);
    dispatch({ type: "FETCH_MAIN_ADV", payload: ITEMS });
  };
};

export const fetchMainNews = (Redis = false, reset = false, domain) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainNews(Redis, reset);
    const podDomain = domain.split(".")[0];
    dispatch({
      type: "FETCH_MAIN_NEWS",
      payload: ITEMS.filter((item) => {
        return (
          !item.PROPERTY_DOMAIN_VALUE ||
          item.PROPERTY_DOMAIN_VALUE.includes(podDomain)
        );
      }),
    });
  };
};

export const fetchMainProductsReviews = (
  Redis = false,
  reset = false,
  domain,
) => {
  return async (dispatch) => {
    const podDomain = domain.split(".")[0];
    const { ITEMS } = await Api.content.getMainProductsReviews(
      Redis,
      reset,
      podDomain,
    );
    dispatch({ type: "FETCH_MAIN_PRODUCT_REVIEWS", payload: ITEMS });
  };
};

export const fetchMainPortfolio = (Redis = false, reset = false, domain) => {
  return async (dispatch) => {
    const podDomain = domain.split(".")[0];
    const { ITEMS } = await Api.content.getMainPortfolio(
      Redis,
      reset,
      podDomain,
    );
    dispatch({ type: "FETCH_MAIN_PORTFOLIO", payload: ITEMS });
  };
};

export const fetchServicePortfolio = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getServicePortfolio(Redis, reset);
    dispatch({ type: "FETCH_MAIN_PORTFOLIO", payload: ITEMS });
  };
};

export const fetchMainReviews = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getMainReviews(Redis, reset);
    dispatch({ type: "FETCH_MAIN_REVIEWS", payload: ITEMS });
  };
};

export const fetchShopReviews = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.fetchShopReviews(Redis, reset);
    dispatch({ type: "FETCH_SHOP_REVIEWS", payload: ITEMS });
  };
};

export const fetchRightNews = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getLastNews(Redis, 2, reset);
    dispatch({ type: "FETCH_LAST_NEWS", payload: ITEMS });
  };
};

export const fetchTeam = (Redis, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getTeam(Redis, reset);
    dispatch({ type: "FETCH_TEAM", payload: ITEMS });
  };
};

export const fetchVideos = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getVideos(Redis, reset);
    dispatch({ type: "FETCH_VIDEOS", payload: ITEMS });
  };
};

export const fetchQuestions = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS, SECTIONS } = await Api.content.getQuestions(Redis, reset);
    dispatch({ type: "FETCH_QUESTIONS", payload: ITEMS });
    dispatch({ type: "FETCH_QUESTION_SECTIONS", payload: SECTIONS });
  };
};

export const fetchReviews = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const { ITEMS } = await Api.content.getReviews(Redis, reset);
    dispatch({ type: "FETCH_REVIEWS", payload: ITEMS });
  };
};

export const fetchCertificates = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const ITEMS = await Api.content.getCertificates(Redis, reset);
    dispatch({ type: "FETCH_CERTIFICATES", payload: Object.values(ITEMS) });
  };
};

export const fetchCatalogBanners = (reset = false) => {
  return async (dispatch) => {
    const data = await Api.server.getCatalogBanners(reset);
    dispatch({ type: "FETCH_CATALOG_BANNERS", payload: data });
  };
};

export const fetchAboutContent = (
  Redis = false,
  reset = false,
  isBaltex = false,
) => {
  return async (dispatch) => {
    const data = isBaltex
      ? await Api.baltex.getAboutContent(Redis, reset)
      : await Api.content.getAboutContent(Redis, reset);
    dispatch({ type: "FETCH_ABOUT", payload: data });
  };
};

export const getSettings = (Redis = false, reset = false) => {
  return async (dispatch) => {
    const data = await Api.content.getSettings(Redis, reset);
    dispatch({ type: "FETCH_SETTINGS", payload: data });
  };
};
