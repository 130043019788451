import { useSelector } from "react-redux";
import { useMemo } from "react";
import styles from "./index.module.scss";
import Button from "../../common/button";

export default function VideoFull() {
  const { data } = useSelector((state) => state.popup);
  const { contactsData } = useSelector((state) => state.contacts);

  const phone = useMemo(() => {
    return contactsData?.PHONE;
  }, [contactsData]);
  return (
    <div className={styles.Content}>
      <iframe
        width="800"
        height="450"
        src={data}
        frameBorder="0"
        allow="clipboard-write; autoplay"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
      <div className={styles.Buttons}>
        <div>
          <a href={`tel:${phone}`} className={contactsData.CALL_TOUCH_CODE}>
            {phone}
          </a>
          <a href={`https://wa.me/+79811779326`}>
            <img src="/videoBanner/wp.svg" />
            Написать в Whatsapp
          </a>
          <a href={`https://telegram.me/farkop_rubot`}>
            <img src="/videoBanner/tg.svg" />
            Написать в Телеграм
          </a>
        </div>

        <Button
          styles={{ width: "200px" }}
          label={"Подобрать фаркоп"}
          onClick={() => {
            window.location.href = "/catalog/farkopy/";
          }}
        ></Button>
      </div>
      <div className={styles.Social}>
        <span>Следите за новостями:</span>
        <div>
          <a href="https://vk.com/farkopru" target="_blank" rel="noreferrer">
            <img src="/videoBanner/vk.svg" />
          </a>
          <a
            href="https://www.drive2.ru/users/farkopru"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/videoBanner/drive2.svg" />
          </a>
          <a href="https://dzen.ru/farkop.ru" target="_blank" rel="noreferrer">
            <img src="/videoBanner/dzen.svg" />
          </a>
          <a
            href="https://telegram.me/farkop_rubot"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/videoBanner/telegram.svg" />
          </a>
          <a
            href="https://www.youtube.com/@farkopru"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/videoBanner/youtube.svg" />
          </a>
          <a
            href="https://rutube.ru/channel/33712357/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/videoBanner/rutube.svg" />
          </a>
        </div>
      </div>
    </div>
  );
}
