import { useMemo } from "react";
import { daysTextForm, declOfNum } from "/utils";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Icon from "../../../common/icon";
import styles from "./index.module.scss";

export default function Delivery({ isBaltex = false, isLanding = false }) {
  const { delivery } = useSelector((state) => state.product);
  const { edost } = useSelector((state) => state.location);
  const { cityName } = useSelector((state) => state.location);
  const { shops } = useSelector((state) => state.location);
  const { contactsData } = useSelector((state) => state.contacts);
  const { edostMinDays } = useSelector((state) => state.location);
  const deliveryDataArray = useMemo(() => {
    const filteredShopsIds = Object.values(shops)
      .filter((shop) => {
        // @ts-ignore
        return contactsData?.SHOPS?.includes(shop.ID);
      })
      .map((shop) => shop.PROP_ID);
    return Object.values(delivery).filter((item) =>
      filteredShopsIds.includes(item.ID),
    );
  }, [delivery, contactsData, shops]);

  const deliveryProductInfo = useMemo(() => {
    const text = [];
    if (contactsData.NAME === "Центральный" && edostMinDays > 0) {
      text.push(`Доставка в г. ${cityName} транспортными компаниями`);
      text.push(`от ${declOfNum(edostMinDays, ["дня", "дней", "дней"])}`);
    } else if (deliveryDataArray?.length > 0) {
      const nowShops = deliveryDataArray.filter(
        (shop) => shop.AVAL_TYPE === "NOW",
      );
      const relocationShops = deliveryDataArray.filter(
        (shop) => shop.AVAL_TYPE === "RELOCATION",
      );
      let relocationMinDays = null;
      if (relocationShops.length > 0) {
        relocationShops.forEach((shop) => {
          if (!relocationMinDays || shop.AVAL_DAYS < relocationMinDays) {
            relocationMinDays = shop.AVAL_DAYS;
          }
        });
      }

      if (nowShops.length > 0) {
        text.push("Есть в наличии на самовывоз из");
        text.push(
          declOfNum(nowShops.length, ["магазина", "магазинов", "магазинов"]),
        );
        text.push(daysTextForm(nowShops[0].AVAL_DAYS));
        if (relocationShops.length > 0) {
          text.push("и под заказ в");
          text.push(
            declOfNum(relocationShops.length, [
              "магазина",
              "магазинов",
              "магазинов",
            ]),
          );
          text.push(daysTextForm(relocationMinDays));
        }
      } else if (relocationShops.length > 0) {
        text.push("Под заказ из");
        text.push(
          declOfNum(relocationShops.length, [
            "магазина",
            "магазинов",
            "магазинов",
          ]),
        );
        text.push(daysTextForm(relocationMinDays));
      }
    }
    return text.join(" ");
  }, [deliveryDataArray, edost]);

  return (
    <div
      className={clsx(
        isBaltex && styles.DeliveryBaltex,
        styles.Delivery,
        isLanding && styles.DeliveryLanding,
      )}
    >
      <div>Доставка</div>
      <div>{deliveryProductInfo}</div>
      {!isBaltex && (
        <div
          className={styles.Link}
          onClick={() => {
            const section = document.getElementById("delivery");
            section.click();
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          Рассчитать стоимость
          {!isLanding && <Icon id="calc" width={18} height={18} />}
        </div>
      )}
    </div>
  );
}
